import { TwilioProjectRelation } from '@c/domain/entities/dialogEngine/TwilioProjectRelation';
import { TwilioPhoneNumber } from '@d/features/Twilio/types/phoneNumber';

export interface TwilioProjectRelationSpecification {
  isValidPhoneNumberSID(phoneNumberSID: string): [boolean, string];
  isValidRejectCallMessage(rejectCallMessage: string): [boolean, string];
  isValidInitialUtterance(twilioInitialUtterance: string): [boolean, string];
}

export class TwilioProjectRelationValidator implements TwilioProjectRelationSpecification {
  public hasProductionPhoneNumberSID(
    twilioPhoneNumbers: TwilioPhoneNumber[],
    twilioProjectRelations: TwilioProjectRelation[]
  ): [boolean, string] {
    if (
      twilioProjectRelations.filter(
        pr =>
          twilioPhoneNumbers.find(phoneNumber => phoneNumber.phoneNumberSID === pr.phoneNumberSID)
            ?.phoneNumberType === 'production'
      ).length > 1
    ) {
      return [false, '本番用の電話番号は1つだけ設定できます'];
    }
    return [true, ''];
  }
  public isValidPhoneNumberSID(phoneNumberSID: string): [boolean, string] {
    if (phoneNumberSID.length === 0) {
      return [false, 'phoneNumberSIDは必須です'];
    } else if (phoneNumberSID.length > 1000) {
      return [false, 'phoneNumberSIDは1000文字以下です'];
    }
    return [true, ''];
  }

  public isValidRejectCallMessage(rejectCallMessage: string): [boolean, string] {
    if (rejectCallMessage.length === 0) {
      return [false, '架電拒否メッセージは必須です'];
    } else if (rejectCallMessage.length > 1000) {
      return [false, '架電拒否メッセージは1000文字以下です'];
    }
    return [true, ''];
  }

  public isValidInitialUtterance(twilioInitialUtterance: string): [boolean, string] {
    if (twilioInitialUtterance.length === 0) {
      return [false, '発話メッセージは必須です'];
    } else if (twilioInitialUtterance.length > 1000) {
      return [false, '発話メッセージは1000文字以下です'];
    }
    return [true, ''];
  }
}
