import { AsyncActionContext } from '@s/reactHooks';
import { ThunkDeps } from '@c/ThunkDeps';
import { State } from '@c/state';
import { genericError } from '@c/application/GenericError';
import { TwilioProjectRelationParameter } from '@c/domain/entities/dialogEngine/TwilioProjectRelation';
import {
  addTwilioProjectRelationSucceeded,
  deleteTwilioProjectRelationSucceeded,
  getTwilioProjectRelationSucceeded,
  resetTwilioProjectRelationSucceeded,
  updateTwilioProjectRelationSucceeded,
} from '@c/modules/twilioProjectRelation/action';
import { getUnicode, Locale } from '@c/shared/utils/i18n';

type Context = AsyncActionContext<ThunkDeps, State>;

export const fetchTwilioProjectRelation =
  ({ context, state, dispatch }: Context) =>
  async (projectId?: string) => {
    const unicode = getUnicode(Locale.jaJP);
    try {
      if (!projectId && !state.env.projectId) {
        return;
      }
      const twilioProjectRelations = await context.twilioProjectRelationQuery.get({
        projectId: projectId || state.env.projectId,
      });
      dispatch(getTwilioProjectRelationSucceeded(twilioProjectRelations));
    } catch (e: any) {
      context.logger.error(unicode.fetch.failToGet({ kinds: 'Twilioプロジェクト設定一覧' }));
      throw genericError({ message: 'Twilioプロジェクト設定一覧の取得に失敗しました' });
    }
  };

export const addTwilioProjectRelation =
  ({ context, state, dispatch }: Context) =>
  async (projectId: string, data: TwilioProjectRelationParameter) => {
    delete (data as any).tmpId;
    data.validate(context.twilioProjectRelationValidator);
    try {
      const twilioProjectRelation = await context.twilioProjectRelationRepository.create({
        projectId,
        data,
      });
      dispatch(addTwilioProjectRelationSucceeded({ twilioProjectRelation }));
    } catch (e: any) {
      context.reportCrashed({ error: e, state });
      throw genericError({ message: 'Twilioプロジェクト設定の追加に失敗しました' });
    }
  };

export const updateTwilioProjectRelation =
  ({ context, state, dispatch }: Context) =>
  async (projectId: string, data: TwilioProjectRelationParameter) => {
    data.validate(context.twilioProjectRelationValidator);
    try {
      const twilioProjectRelation = await context.twilioProjectRelationRepository.update({
        projectId,
        id: data.id!,
        data,
      });
      dispatch(updateTwilioProjectRelationSucceeded({ twilioProjectRelation }));
    } catch (e: any) {
      context.reportCrashed({ error: e, state });
      throw genericError({ message: 'Twilioプロジェクト設定の更新に失敗しました' });
    }
  };

export const deleteTwilioProjectRelation =
  ({ context, state, dispatch }: Context) =>
  async (projectId: string, id: string) => {
    try {
      await context.twilioProjectRelationRepository.delete({ projectId, id });
      dispatch(deleteTwilioProjectRelationSucceeded({ id }));
    } catch (e: any) {
      context.reportCrashed({ error: e, state });
      throw genericError({ message: 'Twilioプロジェクト設定の削除に失敗しました' });
    }
  };

export const resetTwilioProjectRelation =
  ({ context, state, dispatch }: Context) =>
  async () => {
    dispatch(resetTwilioProjectRelationSucceeded());
  };
