/**
 * @fileOverview
 * @author
 */

import { genericError } from '@c/application/GenericError';
import {
  Namespaces,
  C,
  fetchScenarioSucceeded,
  feedbackUpdated,
  edgeConnected,
  initialEdgeConnected,
  edgeDeleted,
  sceneSelected,
  messageAdded,
  messageDeleted,
  messageUpdated,
  textInputStateUpdated,
  sceneDeleted,
  quickRepliesAdded,
  quickRepliesDeleted,
  quickRepliesOrderChanged,
  quickRepliesTextUpdated,
  sceneAdded,
  userConfigSaved,
  queryScenarioEditorUnloaded,
  saveSucceeded,
  messageTypeChanged,
  imageUrlChanged,
  saveRequiredUpdated,
  scenarioStateCleaned,
  switchToOpUpdated,
  customFeedbackUpdated,
  warningDisplayed,
  integrationEndpointUpdated,
  integrationMethodUpdated,
  integrationHeaderAdded,
  integrationHeaderDeleted,
  integrationConditionAdded,
  integrationConditionDeleted,
  integrationHeaderUpdated,
  integrationConditionUpdated,
  sceneParameterUpdated,
  parameterUpdated,
  parameterDeleted,
  dimensionsUpdated,
  dimensionUpdated,
  toAddressUpdated,
  toAddressDeleted,
  toAddressAdded,
  replyToUpdated,
  subjectUpdated,
  bodyUpdated,
  carouselUpdated,
  fallbackUsagedUpdated,
} from '@c/modules/scenarioEditor/action';
import { ActionPayloadWithoutNs } from '@s/typeUtils';
import { dynamciNamespaceIc } from '../dynamicNamespaceIC';
import { required } from '@s/assertions';
import { createRetryHandler } from '@s/io/createRetryHandler';
import { ScenarioEditorCommonState, ScenarioUsingNamespaces } from '@c/state';
import { SceneType } from '@c/application/value/SceneType';
import {
  SceneDimensionCache,
  SceneDimension,
  SceneNodes,
} from '@c/domain/entities/QueryScenarioEditor/SceneNode';
import { Carousel } from '@c/domain/entities/carousel/Carousel';
import { selectCategorySucceeded } from '../queryScenarioEditor/action';
import { getUnicode, Locale } from '@c/shared/utils/i18n';

export const fetchScenario =
  ({ context, dispatch, state }: C) =>
  async (
    namespace: Namespaces,
    { scenarioId, carousels }: { scenarioId: string; carousels?: Carousel[]; csv?: boolean }
  ) => {
    const unicode = getUnicode(Locale.jaJP);
    try {
      const errors = {};
      const scenario = await context.scenarioEditorQuery.find({
        scenarioId,
        projectId: state.env.projectId,
        errors,
        projectType: state.env.type,
        carousels,
      });
      dispatch(fetchScenarioSucceeded(namespace, { scenario, scenarioId, errors }));
    } catch (e: any) {
      context.logger.error(unicode.fetch.failToGet({ kinds: 'シナリオ' }));
      throw genericError({ message: 'シナリオの取得に失敗しました' });
    }
  };

export const fetchCsvScenario =
  ({ context, state }: C) =>
  async () => {
    const unicode = getUnicode(Locale.jaJP);
    try {
      const inquiries = state.queryScenarioEditor.scenarioList[
        state.queryScenarioEditor.selectedCategory.id
      ]!.slice().sort((a, b) => {
        return a.scenarioId < b.scenarioId ? 1 : a.scenarioId > b.scenarioId ? -1 : 0;
      });
      const list = await context.csvWorkerService.createCSV({
        inquiries,
        projectId: state.env.projectId,
      });
      return list;
    } catch (e: any) {
      context.logger.error(unicode.operation.fail({ kinds: 'シナリオCSVのエクスポート' }));
      throw genericError({ message: 'シナリオCSVのエクスポートに失敗しました' });
    }
  };

export const fetchScenarioWithCache =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { scenario, scenarioId, errors }: ActionPayloadWithoutNs<typeof fetchScenarioSucceeded>
  ) => {
    dispatch(fetchScenarioSucceeded(namespace, { scenario, scenarioId, errors }));
  };

export const updateFeedback =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { isEnableFeedback }: ActionPayloadWithoutNs<typeof feedbackUpdated>
  ) => {
    dispatch(feedbackUpdated(namespace, { isEnableFeedback }));
  };

export const updateCustomFeedback =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, isFeedbackOn: boolean) => {
    dispatch(customFeedbackUpdated(namespace, isFeedbackOn));
  };

export const updateParameter =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, payload: ActionPayloadWithoutNs<typeof parameterUpdated>) => {
    dispatch(parameterUpdated(namespace, payload));
  };

export const deleteParameter =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, payload: ActionPayloadWithoutNs<typeof parameterDeleted>) => {
    dispatch(parameterDeleted(namespace, payload));
  };

export const updateSceneParameter =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, payload: ActionPayloadWithoutNs<typeof sceneParameterUpdated>) => {
    dispatch(sceneParameterUpdated(namespace, payload));
  };

export const updateSwitchToOp =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { isEnableSwitchToOp }: ActionPayloadWithoutNs<typeof switchToOpUpdated>
  ) => {
    dispatch(switchToOpUpdated(namespace, { isEnableSwitchToOp }));
  };

export const connectEdge =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { fromSceneId, buttonId, toSceneId }: ActionPayloadWithoutNs<typeof edgeConnected>
  ) => {
    dispatch(edgeConnected(namespace, { fromSceneId, buttonId, toSceneId }));
  };

export const connectInitialEdge =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId }: ActionPayloadWithoutNs<typeof initialEdgeConnected>
  ) => {
    dispatch(initialEdgeConnected(namespace, { sceneId }));
  };

export const deleteEdge =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, buttonId }: ActionPayloadWithoutNs<typeof edgeDeleted>
  ) => {
    dispatch(edgeDeleted(namespace, { sceneId, buttonId }));
  };

export const selectScene =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, { sceneId }: ActionPayloadWithoutNs<typeof sceneSelected>) => {
    dispatch(sceneSelected(namespace, { sceneId }));
  };

export const addMessage =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, { sceneId }: ActionPayloadWithoutNs<typeof messageAdded>) => {
    dispatch(messageAdded(namespace, { sceneId }));
  };

export const deleteMessage =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, messageId }: ActionPayloadWithoutNs<typeof messageDeleted>
  ) => {
    dispatch(messageDeleted(namespace, { sceneId, messageId }));
  };

export const updateMessage =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, messageId, value }: ActionPayloadWithoutNs<typeof messageUpdated>
  ) => {
    dispatch(messageUpdated(namespace, { sceneId, messageId, value }));
  };

export const updateTextInputState =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, payload: ActionPayloadWithoutNs<typeof textInputStateUpdated>) => {
    dispatch(textInputStateUpdated(namespace, payload));
  };

export const deleteScene =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, { sceneId }: ActionPayloadWithoutNs<typeof sceneDeleted>) => {
    dispatch(sceneDeleted(namespace, { sceneId }));
  };

export const quickRepliesAdd =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, { sceneId }: ActionPayloadWithoutNs<typeof quickRepliesAdded>) => {
    dispatch(quickRepliesAdded(namespace, { sceneId }));
  };

export const quickRepliesDelete =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, quickRepliesId }: ActionPayloadWithoutNs<typeof quickRepliesDeleted>
  ) => {
    dispatch(quickRepliesDeleted(namespace, { sceneId, quickRepliesId }));
  };

export const quickRepliesOrderChange =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, quickRepliesId, isDown }: ActionPayloadWithoutNs<typeof quickRepliesOrderChanged>
  ) => {
    dispatch(quickRepliesOrderChanged(namespace, { sceneId, quickRepliesId, isDown }));
  };

export const quickRepliesTextUpdate =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, quickRepliesId, value }: ActionPayloadWithoutNs<typeof quickRepliesTextUpdated>
  ) => {
    dispatch(quickRepliesTextUpdated(namespace, { sceneId, quickRepliesId, value }));
  };

export const addScene =
  ({ dispatch, state }: C) =>
  async (
    namespace: Namespaces,
    option: {
      x: number;
      y: number;
      type: SceneType;
    }
  ) => {
    dispatch(sceneAdded(namespace, { ...option, projectType: state.env.type }));
  };

export const saveUserConfig =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, payload: ActionPayloadWithoutNs<typeof userConfigSaved>) => {
    dispatch(userConfigSaved(namespace, payload));
  };

export const unloadQueryScenarioEditor =
  ({ dispatch }: C) =>
  async (namespace: Namespaces) => {
    dispatch(queryScenarioEditorUnloaded(namespace));
  };

export const updateCarousel =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, carousel }: ActionPayloadWithoutNs<typeof carouselUpdated>
  ) => {
    dispatch(carouselUpdated(namespace, { sceneId, carousel }));
  };

export const updateDimensions =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { dimensions }: { dimensions: ReadonlyDeep<SceneDimensionCache> }
  ) => {
    dispatch(dimensionsUpdated(namespace, dimensions));
  };

export const updateDimension =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { dimension, sceneId }: { dimension: ReadonlyDeep<SceneDimension>; sceneId: string }
  ) => {
    dispatch(dimensionUpdated(namespace, { dimension, sceneId }));
  };

const invokeNamespaceIc = dynamciNamespaceIc<ScenarioEditorCommonState>();
const saveScenarioRetryHandler = createRetryHandler();
export const saveScenario =
  ({ context, dispatch, state }: C) =>
  async (
    namespace: Namespaces,
    { dimensions }: { dimensions?: ReadonlyDeep<SceneDimensionCache> } = {}
  ) => {
    const commonState = invokeNamespaceIc({
      state,
      namespace,
      isFromRoot: true,
    });
    dispatch(
      dimensionsUpdated(namespace, dimensions ?? commonState.selectedScenario!.scenes.dimensions)
    );
    await saveScenarioRetryHandler(commonState.scenarioId, async () => {
      await context.scenarioRepository.update({
        nodes: {
          ...required(commonState.selectedScenario?.scenes),
          dimensions: dimensions ?? {},
          parameters: commonState.parameters,
        },
        scenarioId: commonState.scenarioId,
        projectId: state.env.projectId,
      });
      dispatch(saveSucceeded(namespace));
    });
  };

export const saveScenarioFromCsv =
  ({ context, getAsyncActionContext, dispatch, state }: C) =>
  async (namespace: ScenarioUsingNamespaces, csv: string) => {
    if (getAsyncActionContext) {
      await context.csvImportService.processCSV({ namespace, csv, getAsyncActionContext });
      dispatch(selectCategorySucceeded({ category: state.queryScenarioEditor.selectedCategory }));
    }
  };

export const copyScenario =
  ({ context, dispatch, state }: C) =>
  async (
    namespace: Namespaces,
    {
      scenarioId,
      scenes,
    }: {
      scenarioId: string;
      scenes: ReadonlyDeep<SceneNodes>;
    }
  ) => {
    await saveScenarioRetryHandler(scenarioId, async () => {
      await context.scenarioRepository.update({
        nodes: {
          ...required(scenes),
          dimensions: scenes.dimensions,
          parameters: scenes.parameters,
          updated: true,
        },
        scenarioId,
        projectId: state.env.projectId,
      });
      dispatch(saveSucceeded(namespace));
    });
  };

export const messageTypeChange =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { type, id }: ActionPayloadWithoutNs<typeof messageTypeChanged>
  ) => {
    dispatch(messageTypeChanged(namespace, { type, id }));
  };

export const imageUrlChange =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, { url, id }: ActionPayloadWithoutNs<typeof imageUrlChanged>) => {
    dispatch(imageUrlChanged(namespace, { url, id }));
  };

export const updateSaveRequired =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { isSaveRequired }: ActionPayloadWithoutNs<typeof saveRequiredUpdated>
  ) => {
    dispatch(saveRequiredUpdated(namespace, { isSaveRequired }));
  };

export const cleanupScenarioState =
  ({ dispatch }: C) =>
  async (namespace: Namespaces) => {
    dispatch(scenarioStateCleaned(namespace));
  };

export const cleanupWarning =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, warningType: keyof ScenarioEditorCommonState['warnings']) => {
    dispatch(warningDisplayed(namespace, warningType));
  };

export const updateIntegrationEndpoint =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, endpoint }: ActionPayloadWithoutNs<typeof integrationEndpointUpdated>
  ) => {
    dispatch(integrationEndpointUpdated(namespace, { sceneId, endpoint }));
  };

export const updateIntegrationMethod =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, method }: ActionPayloadWithoutNs<typeof integrationMethodUpdated>
  ) => {
    dispatch(integrationMethodUpdated(namespace, { sceneId, method }));
  };

export const addIntegrationHeader =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, header }: ActionPayloadWithoutNs<typeof integrationHeaderAdded>
  ) => {
    dispatch(integrationHeaderAdded(namespace, { sceneId, header }));
  };

export const deleteIntegrationHeader =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, headerFieldName }: ActionPayloadWithoutNs<typeof integrationHeaderDeleted>
  ) => {
    dispatch(integrationHeaderDeleted(namespace, { sceneId, headerFieldName }));
  };

export const updateIntegrationHeader =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, previousHeaderName, header }: ActionPayloadWithoutNs<typeof integrationHeaderUpdated>
  ) => {
    dispatch(integrationHeaderUpdated(namespace, { sceneId, previousHeaderName, header }));
  };

export const addIntegrationCondition =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, label }: ActionPayloadWithoutNs<typeof integrationConditionAdded>
  ) => {
    dispatch(integrationConditionAdded(namespace, { sceneId, label }));
  };

export const updateIntegrationCondition =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, conditionId, condition }: ActionPayloadWithoutNs<typeof integrationConditionUpdated>
  ) => {
    dispatch(integrationConditionUpdated(namespace, { sceneId, conditionId, condition }));
  };

export const deleteIntegrationCondition =
  ({ dispatch }: C) =>
  async (
    namespace: Namespaces,
    { sceneId, conditionId }: ActionPayloadWithoutNs<typeof integrationConditionDeleted>
  ) => {
    dispatch(integrationConditionDeleted(namespace, { sceneId, conditionId }));
  };

export const updateToAddress =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, payload: ActionPayloadWithoutNs<typeof toAddressUpdated>) => {
    dispatch(toAddressUpdated(namespace, payload));
  };

export const deleteToAddress =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, payload: ActionPayloadWithoutNs<typeof toAddressDeleted>) => {
    dispatch(toAddressDeleted(namespace, payload));
  };

export const addToAddress =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, payload: ActionPayloadWithoutNs<typeof toAddressAdded>) => {
    dispatch(toAddressAdded(namespace, payload));
  };

export const updateReplyTo =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, payload: ActionPayloadWithoutNs<typeof replyToUpdated>) => {
    dispatch(replyToUpdated(namespace, payload));
  };

export const updateSubject =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, payload: ActionPayloadWithoutNs<typeof subjectUpdated>) => {
    dispatch(subjectUpdated(namespace, payload));
  };

export const updateBody =
  ({ dispatch }: C) =>
  async (namespace: Namespaces, payload: ActionPayloadWithoutNs<typeof bodyUpdated>) => {
    dispatch(bodyUpdated(namespace, payload));
  };

export const updateFallbackUsage =
  ({ dispatch, state }: C) =>
  async (namespace: Namespaces, isEnabled: boolean) => {
    dispatch(fallbackUsagedUpdated(namespace, isEnabled, state.env.type));
  };
