/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router';
// eslint-disable-next-line react/no-deprecated
import { createPortal, render } from 'react-dom';
import { Login } from '@c/components/environments/Login';
import styled from '@emotion/styled';
import { css, Global } from '@emotion/react';
import { resetStyle } from '@s/components/atom/resetStyle';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import reducers from '@c/modules';
import { aimIconCss } from '@c/components/atom/Icon';
import { AccountRepository } from './repository/AccountRepository';
import { UsersRepository } from './repository/UsersRepository';
import { initialState } from './state';
import { createBrowserHistory } from 'history';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { getAuth } from 'firebase/auth';
import { PageLoading } from './components/molecule/PageLoading';
import { ToastContainer } from 'react-toastify';
import { Notificator, toastStyle } from './components/atom/Toast';
import { ScenarioEditorService } from '@c/application/service/ScenarioEditorService';
import { ThunkDeps } from './ThunkDeps';
import { ScenarioEditorRepository } from './repository/ScenarioEditorRepository';
import {
  scenarioNodesAdapterFactory,
  sceneNodesToRemoteSceneNode,
} from './adapter/scenarioNodesAdapter';
import {
  remoteJsonNodesTocsvNodes,
  csvToSceneNodesAdapterFactory,
} from './adapter/scenarioCsvNodesAdapter';
import { ResolutionTrainingListQuery } from './query/AICompassAnswersQuery';
import { ScenarioEditorQuery } from './query/ScenarioEditorQuery';
import { UsersQuery } from './query/UsersQuery';
import { MatchingQuery, MatchingSearchQuery } from './query/AICompassMatchingQuery';
import { CategoryQuery } from './query/CategoryQuery';
import { CategoryRepository } from './repository/CategoryRepository';
import { CategoryOrderRepository } from './repository/CategoryOrderRepository';
import { staticConfig, TENANT_NAME } from './config';
import { Env } from '@c/components/environments/Env';
import { EnvQuery, ProjectIdQuery } from './query/EnvQuery';
import { InquiryEditorSpecification } from './domain/specification/InquirySpecification';
import { SystemMessagesQuery } from './query/SystemMessagesQuery';
import { SystemMessagesRepository } from './repository/SystemMessagesRepository';
import { FixedMessageQuery } from './query/FixedMessage';
import { FixedMessageRepository } from './repository/FixedMessageRepository';
import { ScenarioRepository } from './repository/ScenarioRepository';
import { SynonymQuery } from './query/SynonymQuery';
import { VersionRepository } from './repository/VersionRepository';
import { MediaStorageQuery } from './query/MediaStorageQuery';
import { MediaStorageRepository } from '@s/repository/MediaStorageRepository';
import { WidgetCustomizationQuery } from './query/WidgetCustomizationQuery';
import { registerCommonFetchErrorHandler, registerPreFetchIntercepter } from '@s/io/fetchService';
import { ResolutionTrainingRepository } from './repository/ResolutionTrainingRepository';
import { MatchingTrainingRepository } from './repository/MatchingTrainingRepository';
import { checkLoginState } from '@c/modules/auth/usecase';
import { WidgetCustomizationRepository } from './repository/WidgetCustomizationRepository';
import { WelcomeMessageQuery } from './query/WelcomeMessageQuery';
import { promotionErrorAdapter } from './adapter/promotionErrorAdapter';
import { ScenarioSpecification } from './domain/specification/SceneNodeSpecification';
import { UpdateFoundNotification } from './components/ecosystem/UpdateFoundNotification';
import { AccountProfileValidator } from './domain/specification/AccountSpecification';
import './immerIE11Fix';
import { MediaValidator } from './domain/specification/MediaSpecification';
import { ActionLogQueuingService } from '@s/application/service/ActionLogQueuingService';
import { CrashReporter } from '@s/crashreporting/CrashReporter';
import { FirestoreHandler } from './firebase/FirestoreHandler';
import { FirestoreService } from './domain/service/FirestoreService';
import { FirestoreConnector } from './firebase/FirestoreConnector';
import { crashReporterFactory } from './crashReporterFactory';
import { PreviewRepository } from './repository/PreviewRepository';
import { PreviewCustomerRepository } from './repository/PreviewCustomerRepository';
import * as synonymAdapter from './adapter/synonymAdapter';
import { SynonymRepository } from './repository/SynonymRepository';
import { mdStyle } from '@s/components/atom/mdstyle';
import { AsyncActionContext } from '@s/reactHooks';
import { ScenarioIdConvertionService } from './application/service/ScenarioIdConvertionService';
import { OperationHistoriesQuery } from './query/OperationHistoriesQuery';
import { BusinessHourPatternQuery } from '@c/query/BusinessHourPatternQuery';
import { BusinessHourScheduleQuery } from '@c/query/BusinessHourScheduleQuery';
import { BusinessHourPatternRepository } from '@c/repository/BusinessHourPatternRepository';
import { BusinessHourScheduleRepository } from '@c/repository/BusinessHourScheduleRepository';
import { AnalyzedScenarioQuery } from './query/AnalyzedScenarioQuery';
import { firestoreConversationViewAdapter } from './adapter/firestoreConversationViewAdapter';
import { firestoreCustomerAdapter } from './adapter/firestoreCustomerAdapter';
import { firestoreChatMessageAdapter } from './adapter/firestoreChatMessageAdapter';
import { CustomerRepository } from '@c/repository/CustomerRepository';
import { ConversationAssingeeRepository } from './repository/ConversationAssigneeRepository';
import { ConversationStateOpQueue } from './application/service/ConversationStateOpQueue';
import { LabelQuery } from '@c/query/LabelQuery';
import { LabelRepository } from '@c/repository/LabelRepository';
import { LabelValidator } from '@c/domain/specification/LabelSpecification';
import { ConversationLabelRepository } from '@c/repository/ConversationLabelRepository';
import { ChatMessageService } from './application/service/ChatMessageService';
import { MessageEventRepository } from '@aim/shared/src/repository/EventRepository';
import { InquiryOrderRepository } from '@c/repository/InquiryOrderRepository';
import { CustomerDataExportSettingsQuery } from '@c/query/CustomerDataExportSettingsQuery';
import { CustomerDataExportSettingRepository } from '@c/repository/CustomerDataExportSettingRepository';
import { CustomerDataExportSettingValidator } from '@c/domain/specification/CustomerDataExportSettingSpecification';
import { CustomerMediaQuery } from '@s/query/CustomerMediaQuery';
import { CustomerImageUploadRequestRepository } from '@c/repository/CustomerImageUploadRequestRepository';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { WidgetCustomizationSpecification } from '@c/domain/specification/WhitelistSpecification';
import { ConsoleEnvContext, environment } from './components/atom/ConsoleEnvContext';
import { WidgetInspectorWindowQuery } from './query/WidgetInspectorWindowQuery';
import { WidgetInspectorWindowRepository } from './repository/WidgetInspectorWindowRepository';
import { DebuggerWindowConnectionService } from './application/service/DebuggerWindowConnectionService';
import { ProjectListQuery } from './query/ProjectListQuery';
import { ProjectRepository } from './repository/ProjectRepository';
import { ProjectQuery } from './query/ProjectQuery';
import { ConversationEventQuery } from '@c/query/voice/ConversationEventQuery';
import { VoiceCustomersRepository } from '@c/repository/voice/VoiceCustomersRepository';
import { ConversationQuery } from '@c/query/voice/ConversationQuery';
import { EntityHeadersQuery } from '@c/query/voice/EntityHeadersQuery';
import { VoiceCustomerAttributeQuery } from '@c/query/voice/VoiceCustomerAttributeQuery';
import { VoiceEntityRepository } from '@c/repository/voice/VoiceEntityRepository';
import { RecordingInfoQuery } from '@c/query/voice/RecordingInfoQuery';
import { FlyweightGlobalTooltip } from './components/atom/Tooltip';
import { EntityDataValidator } from '@c/domain/specification/EntityDataSpecification';
import { VoiceSettingQuery } from '@c/query/voice/VoiceSettingQuery';
import { VoiceSettingRepository } from '@c/repository/voice/VoiceSettingRepository';
import { FbService } from './domain/service/FbService';
import { CarouselQuery } from '@c/query/CarouselQuery';
import { CarouselRepository } from '@c/repository/CarouselRepository';
import { FacebookAppRepository } from './repository/FacebookAppRepository';
import { FacebookPageRepository } from './repository/FacebookPageRepository';
import { InstagramSettingQuery } from './query/InstagramSettingQuery';
import { IgAutoMessageRepository } from '@c/repository/IgAutoMessageRepository';
import { IgAutoMessageQuery } from '@c/query/IgAutoMessageQuery';
import { DataExportScheduleQuery } from '@c/query/DataExportScheduleQuery';
import { DataExportScheduleRepository } from '@c/repository/DataExportScheduleRepository';
import { LineProjectRepository } from './repository/LineRepository';
import { LineSettingQuery } from './query/LineSettingQuery';
import { lineSettingAdapter } from './adapter/lineSettingAdapter';
import { PhoneAuth } from '@c/components/environments/PhoneAuth';
import { MultiFactorAuth } from '@c/components/environments/MultiFactorAuth';
import { VerificationCodeSender } from '@c/components/environments/VerificationCodeSender';
import { NgWordQuery } from './query/NgWordQuery';
import { NotificationsQuery } from './query/NotificationsQuery';
import { TwilioProjectRelationQuery } from '@c/query/dialogEngine/TwilioProjectRelationQuery';
import { TwilioProjectRelationRepository } from '@c/repository/dialogEngine/TwilioProjectRelationRepository';
import { calendarStyle } from '@c/components/organism/BusinessHourSetting';
import { TwilioProjectRelationValidator } from '@c/domain/specification/dialogEngine/TwilioProjectRelationSpecification';
// import { DialogEngineRouteDraftRepository } from '@c/repository/dialogEngine/DialogEngineRouteDraftRepository';
import { CSVWorkerService } from './domain/service/CSVWorkerService';
import { CSVSpecificationImpl } from './domain/specification/CSVSpecification';
import { CSVImportServiceImpl } from './application/service/CSVImportService';
import { LLMVersionRepository } from './repository/LLMVersionRepository';
import { LoggerFactory } from './infrastructure/loggers';
import { withDevCycleProvider } from '@devcycle/react-client-sdk';
import { Locale, getUnicode } from './shared/utils/i18n';
import { logger as deLogger, LoggerContext } from '@d/contexts/loggerContext'; // FIXME: DialogEngine用のLogger. build, deployを分けた際には削除する *
import { DialogEngineEnvContext, DialogEngineEnvironment } from '@d/contexts/envContext';
// NOTE: PWA対応でsw.jsという service worker を設定していたが廃止
// 古いservice workerを削除する
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .getRegistrations()
    .then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister();
      }
    })
    .catch(function (error) {
      console.error('Service Workerの解除中にエラーが発生しました:', error);
    });
}

const history = createBrowserHistory({
  basename: '/',
});

const ContentContainerElement = styled.div`
  width: 100%;
  height: 100%;
`;

const globalStyle = css`
  ${resetStyle};
  #app,
  html,
  body {
    width: 100%;
    height: 100%;
  }
`;

/* eslint-disable @typescript-eslint/naming-convention */
const Authorized = React.lazy(async () =>
  import(/* webpackChunkName: "authorized" */ '@c/components/environments/Authorized').then(
    ({ Authorized }) => ({
      default: Authorized,
    })
  )
);

const Reminder = React.lazy(async () =>
  import(/* webpackChunkName "reminder" */ '@c/components/environments/Reminder').then(
    ({ Reminder }) => ({
      default: Reminder,
    })
  )
);

const RegisterOperator = React.lazy(async () =>
  import(
    /* webpackChunkName "register-operator" */ '@c/components/environments/RegisterOperator'
  ).then(({ RegisterOperator }) => ({
    default: RegisterOperator,
  }))
);
/* eslint-enable @typescript-eslint/naming-convention */

const getAsyncActionContextValues = () => ({
  context: asyncActionContext,
  state: store.getState(),
  dispatch: store.dispatch,
  getState: () => store.getState(),
  mutate: (_a: any, b: any) => Promise.resolve(b),
});

const isPublicGCSBucketAccess = (url: string) =>
  url.includes('welcome_message.json') ||
  url.includes('configuration.json') ||
  url.includes('configuration-sp.json');

registerPreFetchIntercepter(async (url, req) => {
  if (!req.headers) {
    req.headers = {};
  }

  if (
    !url.includes('welcome_message.json') &&
    !url.includes('configuration.json') &&
    !url.includes('configuration-sp.json') &&
    !(url.includes('data-export') && process.env.NODE_ENV === 'production') &&
    !url.includes('audio.wav') &&
    !url.includes('graph.facebook.com')
  ) {
    req.headers['X-AIM-Subdomain'] = TENANT_NAME;
  }
  if (url.includes('/operator/verify') || url.includes('graph.facebook.com')) {
    return req;
  }
  const user = getAuth().currentUser;
  if (user) {
    await checkLoginState(getAsyncActionContextValues())();
    // NOTE: GCS に authorization header を付けてリクエストすると401エラーになってしまう
    // ref: https://cloud.google.com/storage/docs/troubleshooting?hl=ja#401_unauthorized
    if (!req.headers['authorization'] && !isPublicGCSBucketAccess(url)) {
      const idToken = await user.getIdToken();
      req.headers['authorization'] = `Bearer ${idToken}`;
    }
  }
  return req;
});

const composeEnhancer =
  process.env.NODE_ENV !== 'production'
    ? (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose
    : compose;

const store = createStore(
  reducers({
    history,
    scenarioEditorService: new ScenarioEditorService(),
    actionLogQueuingService: ActionLogQueuingService.getInstance(),
  }),
  initialState,
  composeEnhancer(applyMiddleware(routerMiddleware(history)))
);

const scenarioNodesAdapter = scenarioNodesAdapterFactory(new ScenarioSpecification());
// const scenarioCsvNodesAdapter = scenarioNodesCsvAdapterFactory();

const logger = LoggerFactory.createLogger(staticConfig.env);
const unicode = getUnicode(Locale.jaJP);

const notificator = new Notificator();
registerCommonFetchErrorHandler({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  401: () => {
    try {
      if (getAuth().currentUser) {
        checkLoginState(getAsyncActionContextValues())();
      } else {
        history.push('/login');
      }
    } catch (e) {
      history.push('/login');
    }
  },
  403: () => {
    notificator.error(unicode.error.authorization());
    logger.error(unicode.error.authorization());
  },
});

const firestoreHandler = new FirestoreHandler();
const firestoreService = new FirestoreService(
  20,
  new FirestoreConnector(firestoreHandler),
  firestoreConversationViewAdapter,
  firestoreChatMessageAdapter,
  firestoreCustomerAdapter,
  async () => {
    const state = store.getState().auth;
    const entity = state.authEntity;
    if (entity && entity.claims.exp && new Date(entity.claims.exp * 1000 - 6000) <= new Date()) {
      await checkLoginState(getAsyncActionContextValues())();
    }
  }
);
const reportCrashed = crashReporterFactory({
  crashReporter: CrashReporter.getInstance().init({
    dsn: staticConfig.sentry.dsn,
    environment: staticConfig.env,
    release: import.meta.env.VITE_COMMIT_HASH,
  }),
  actionLogQueuingService: ActionLogQueuingService.getInstance(),
  firestoreService,
});

const csvToSceneNodesAdapter = csvToSceneNodesAdapterFactory({
  scenarioNodesAdapter,
  csvSpecification: new CSVSpecificationImpl(),
});
const asyncActionContext: ThunkDeps = {
  scenarioIdConvertionService: new ScenarioIdConvertionService(),
  accountRepository: new AccountRepository(),
  scenarioEditorRepository: new ScenarioEditorRepository(),
  scenarioEditorQuery: new ScenarioEditorQuery({
    scenarioNodesAdapter,
    scenarioNodesCsvAdapter: remoteJsonNodesTocsvNodes,
  }),
  resolutionTrainingListQuery: new ResolutionTrainingListQuery(),
  usersQuery: new UsersQuery(async () => {
    await checkLoginState(getAsyncActionContextValues())();
  }),
  usersRepository: new UsersRepository(),
  matchingClustersQuery: new MatchingQuery(),
  categoryQuery: new CategoryQuery(),
  categoryRepository: new CategoryRepository(),
  categoryOrderRepository: new CategoryOrderRepository(),
  projectIdQuery: new ProjectIdQuery(),
  envQuery: new EnvQuery(),
  inquiryEditorSpecification: InquiryEditorSpecification.getInstance(),
  fixedMessageQuery: new FixedMessageQuery(),
  fixedMessageRepository: new FixedMessageRepository(),
  systemMessagesQuery: new SystemMessagesQuery(),
  systemMessagesRepository: new SystemMessagesRepository(),
  scenarioRepository: new ScenarioRepository({
    sceneNodesToRemoteSceneNode,
    csvToSceneNodesAdapter,
  }),
  synonymQuery: new SynonymQuery(synonymAdapter.fromRemote),
  synonymRepository: new SynonymRepository(synonymAdapter.fromLocal),
  versionRepository: new VersionRepository(),
  llmVersionRepository: new LLMVersionRepository(),
  mediaStorageQuery: new MediaStorageQuery(),
  mediaStorageRepository: new MediaStorageRepository(),
  widgetCustomizationQuery: new WidgetCustomizationQuery(),
  widgetCustomizationRepository: new WidgetCustomizationRepository(),
  widgetCustomizationSpecification: new WidgetCustomizationSpecification(),
  resolutionTrainingRepository: new ResolutionTrainingRepository(sceneNodesToRemoteSceneNode),
  matchingTrainingRepository: new MatchingTrainingRepository(sceneNodesToRemoteSceneNode),
  matchingSearchQuery: new MatchingSearchQuery(),
  welcomeMessageQuery: new WelcomeMessageQuery(),
  accountProfileValidator: new AccountProfileValidator(),
  promotionErrorAdapter,
  mediaValidator: new MediaValidator(),
  actionLogQueuingService: ActionLogQueuingService.getInstance(),
  reportCrashed,
  logger,
  firestoreService,
  crashReporter: CrashReporter.getInstance().init({
    dsn: staticConfig.sentry.dsn,
    environment: staticConfig.env,
    release: import.meta.env.VITE_COMMIT_HASH,
  }),
  previewRepository: new PreviewRepository(),
  previewCustomerRepository: new PreviewCustomerRepository(),
  analyzedScenarioQuery: new AnalyzedScenarioQuery(scenarioNodesAdapter),
  operationHistories: new OperationHistoriesQuery(),
  businessHourPatternQuery: new BusinessHourPatternQuery(),
  businessHourPatternRepository: new BusinessHourPatternRepository(),
  businessHourScheduleQuery: new BusinessHourScheduleQuery(),
  businessHourScheduleRepository: new BusinessHourScheduleRepository(),
  customerRepository: new CustomerRepository(),
  conversationAssigneeRepository: new ConversationAssingeeRepository(),
  conversationStateOpQueue: new ConversationStateOpQueue(60000),
  labelQuery: new LabelQuery(),
  labelRepository: new LabelRepository(),
  labelValidator: new LabelValidator(),
  conversationLabelRepository: new ConversationLabelRepository(),
  chatMessageService: new ChatMessageService(
    new MessageEventRepository(() => staticConfig.endpoints.message)
  ),
  inquiryOrderRepository: new InquiryOrderRepository(),
  customerDataExportSettings: new CustomerDataExportSettingsQuery(),
  customerDataExportSettingRepository: new CustomerDataExportSettingRepository(),
  customerDataExportSettingValidator: new CustomerDataExportSettingValidator(),
  customerMediaQuery: new CustomerMediaQuery(),
  customerImageUploadRequestRepository: new CustomerImageUploadRequestRepository(),
  widgetInspectorWindowQuery: new WidgetInspectorWindowQuery(),
  widgetInspectorWindowRepository: new WidgetInspectorWindowRepository(),
  debuggerWindowConnectionService: new DebuggerWindowConnectionService(),
  projectListQuery: new ProjectListQuery(),
  projectRepository: new ProjectRepository(),
  projectQuery: new ProjectQuery(),
  voiceCustomerAttributeQuery: new VoiceCustomerAttributeQuery(),
  voiceCustomersRepository: new VoiceCustomersRepository(),
  voiceEntityRepository: new VoiceEntityRepository(),
  voiceConversationQuery: new ConversationQuery(),
  voiceEntityHeadersQuery: new EntityHeadersQuery(),
  voiceConversationEventQuery: new ConversationEventQuery(),
  voiceRecordingInfoQuery: new RecordingInfoQuery(),
  entityDataValidator: new EntityDataValidator(),
  voiceSettingQuery: new VoiceSettingQuery(),
  voiceSettingRepository: new VoiceSettingRepository(),
  fbService: new FbService(),
  carouselQuery: new CarouselQuery(),
  carouselRepository: new CarouselRepository(),
  facebookAppRepository: new FacebookAppRepository(),
  facebookPageRepository: new FacebookPageRepository(),
  instagramSettingQuery: new InstagramSettingQuery(),
  igAutoMessageRepository: new IgAutoMessageRepository(),
  igAutoMessageQuery: new IgAutoMessageQuery(),
  dataExportScheduleQuery: new DataExportScheduleQuery(),
  dataExportScheduleRepository: new DataExportScheduleRepository(),
  lineProjectRepository: new LineProjectRepository(),
  lineSettingQuery: new LineSettingQuery(lineSettingAdapter),
  ngWordQuery: new NgWordQuery(),
  notificationsQuery: new NotificationsQuery(),
  twilioProjectRelationQuery: new TwilioProjectRelationQuery(),
  twilioProjectRelationRepository: new TwilioProjectRelationRepository(),
  twilioProjectRelationValidator: new TwilioProjectRelationValidator(),
  // dialogEngineRouteDraftRepository: new DialogEngineRouteDraftRepository(),
  csvWorkerService: new CSVWorkerService(),
  scenariooNodeAdapter: scenarioNodesAdapter,
  csvImportService: new CSVImportServiceImpl(),
};

const reactFlowStyle = css`
  .react-flow__attribution {
    display: none;
  }
`;
class RootContainer extends React.Component {
  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    reportCrashed({
      error,
      state: store.getState(),
      additionalData: errorInfo,
    });
  }
  public render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <AsyncActionContext.Provider value={asyncActionContext}>
          <Provider store={store}>
            {/* FIXME: DialogEngine用のLogger. build, deployを分けた際には削除する */}
            <LoggerContext.Provider value={deLogger}>
              <DialogEngineEnvContext.Provider value={DialogEngineEnvironment}>
                <ConsoleEnvContext.Provider value={environment}>
                  <ConnectedRouter history={history}>
                    <Global styles={globalStyle} />
                    <Global styles={aimIconCss} />
                    <Global styles={toastStyle} />
                    <Global styles={calendarStyle} />
                    <Global styles={reactFlowStyle} />
                    <Global styles={mdStyle('.markdown-preview')} />
                    <Global styles={mdStyle('.aim__widget-plain-text-message__md')} />
                    <FlyweightGlobalTooltip />
                    {!environment.isSupportedMobileBrowser && <UpdateFoundNotification />}
                    <ContentContainerElement>
                      {createPortal(<ToastContainer />, document.body)}
                      <Env>
                        <Switch>
                          <Route exact={true} path="/" component={Login} />
                          <Route path="/login" component={Login} />
                          <Route
                            path="/a"
                            render={() => (
                              <Suspense fallback={<PageLoading />}>
                                <Authorized />
                              </Suspense>
                            )}
                          />
                          <Route
                            path="/reminder"
                            render={() => (
                              <Suspense fallback={<PageLoading />}>
                                <Reminder />
                              </Suspense>
                            )}
                          />
                          <Route path="/reset-password" render={() => <div></div>} />
                          <Route
                            path="/register"
                            render={() => (
                              <Suspense fallback={<PageLoading />}>
                                <Reminder type="issue" />
                              </Suspense>
                            )}
                          />
                          <Route
                            path="/register-operator"
                            render={() => (
                              <Suspense fallback={<PageLoading />}>
                                <RegisterOperator />
                              </Suspense>
                            )}
                          />
                          <Route
                            path="/multi-factor-register"
                            render={() => (
                              <Suspense fallback={<PageLoading />}>
                                <MultiFactorAuth type="register" />
                              </Suspense>
                            )}
                          />
                          <Route
                            path="/phone-auth"
                            render={() => (
                              <Suspense fallback={<PageLoading />}>
                                <PhoneAuth />
                              </Suspense>
                            )}
                          />
                          <Route
                            path="/multi-factor-auth"
                            render={() => (
                              <Suspense fallback={<PageLoading />}>
                                <MultiFactorAuth type="verify" />
                              </Suspense>
                            )}
                          />
                          <Route
                            path="/send-verification-code"
                            render={() => (
                              <Suspense fallback={<PageLoading />}>
                                <VerificationCodeSender />
                              </Suspense>
                            )}
                          />
                        </Switch>
                      </Env>
                    </ContentContainerElement>
                  </ConnectedRouter>
                </ConsoleEnvContext.Provider>
              </DialogEngineEnvContext.Provider>
            </LoggerContext.Provider>
          </Provider>
        </AsyncActionContext.Provider>
      </DndProvider>
    );
  }
}

// NOTE: DevCycleのSDKを利用するために、RootContainerをDevCycleProviderでラップする
// ref: https://docs.devcycle.com/sdk/client-side-sdks/react/react-gettingstarted#non-blocking
const RootContainerWithDevCycle = withDevCycleProvider({
  sdkKey: import.meta.env.VITE_DEVCYCLE_SDK_KEY,
})(RootContainer);

/*
React18 の Auto batching を有効にしてしまうと、useEffectが連鎖している箇所で
以前と挙動が変わってしまうため、今はOFFにしている。
UseEffect連鎖のりファクタが終わったら、再度有効にする。
https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
*/
render(<RootContainerWithDevCycle />, document.querySelector('#app'));
